<template>
  <main
    class="color-primary h-100 blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <section class="tourism-product__gallery">
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-1">
        <img class="tourism-product__image" src="/assets/img/pool-hotel.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-2">
        <img class="tourism-product__image" src="/assets/img/hotel.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-3">
        <img class="tourism-product__image" src="/assets/img/hall.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-4">
        <img class="tourism-product__image" src="/assets/img/terrace.png" alt="">
      </div>
      <div class="tourism-product__image-wrapper tourism-product__image-wrapper-5">
        <img class="tourism-product__image" src="/assets/img/pool-croped.png" alt="">
      </div>
      <a class="link tourism-product__more-photo" href=""></a>
    </section>

    <section class="tourism-container">
      <div class="row">
        <div class="col-md-9 text-center">
          <h4 class="my10">{{ $t('CART.ORDER_SUMMARY') }}</h4>
          <h4 class="">{{ $t('TOURISM.ORDER_SUMMARY_TXT') }}</h4>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <div class="bg-white p5 bd-radius">
            <div class="d-flex align-items-center flex-wrap m-t-5">
              <h4 class="mx5 m-b-10">{{ $t('TOURISM.TRIP_SUMMARY') }}</h4>
              <TripSummary class="mx15 m-b-10"></TripSummary>
            </div>
            <div class="row m-t-5 m-l-20">
              <div class="col-md-4">
                <FligntTicket></FligntTicket>
              </div>
              <div class="col-md-4">
                <FligntTicket></FligntTicket>
              </div>
              <div class="col-md-4">
                <BaggageInfo></BaggageInfo>
                <CovideNotify class="m-t-15"></CovideNotify>
              </div>
            </div>
            <div class="row m-t-0 m-b-5">
              <div class="col-md-8 fs14 text-center">
                4 nights in Berln then <b>depart from Willy Brandt (BER)</b> 
              </div>
            </div>
          </div>
          <button class="primary-btn primary-btn_sm bg-grey9 close-btn">
            {{ $t("COMMON.CLOSE") }}
          </button>
        </div>
        <div class="col-md-3">
          <FlightResult></FlightResult>
          <div class="bg-white fs14 m-t-20">
            <p class="text-center py5">
              <b>{{ $t('TOURISM.PASSENGERS_PASSPORT_DETAILS') }}</b>
            </p>
            <div class="d-flex justify-content-between flex-wrap passports bg-grey4">
              <TourPassport class="p5"></TourPassport>
              <TourPassport class="p5"></TourPassport>
              <TourPassport class="p5"></TourPassport>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import  FligntTicket from "../../../components/tourism/tradeShow/FligntTicket.vue";
import  BaggageInfo from "../../../components/tourism/tradeShow/BaggageInfo.vue";
import  CovideNotify from "../../../components/tourism/tradeShow/CovideNotify.vue";
import  TripSummary from "../../../components/tourism/tradeShow/TripSummary.vue";
import  FlightResult from "../../../components/tourism/flight/FlightResult.vue";
import  TourPassport from "../../../components/tourism/TourPassport.vue";
export default {
  name: "FlightOrderSummaryBooking",
  components: {
    FligntTicket,
    BaggageInfo,
    CovideNotify,
    TripSummary,
    FlightResult,
    TourPassport
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";
.result-block {
  padding: 10px 20px;
}

.total-value {
  border-top: 1px solid #002036;
}

.order-btn {
  margin: 0;
  margin-top: 15px;
}

.baggage-summary {
  .cabin-baggage {
    border-right: 1px solid #E7E7E8;
  }
  .bd-botton {
    border-bottom: 1px solid #c5c5c5;
  }
  .radio-label {
    .baggage-title {
      width: 40%;
    }
    .size {
      width: 40%;
    }
    .price {
      width: 20%;
    }
  }
}

.close-btn {
  margin: 15px auto;
}

.passports {
  padding: 10px 15px;
}
</style>